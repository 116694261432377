.header-spacer {
  height: 70px;
}
.header {
  .web-container {
    position: fixed;
    left: 0;
    right: 0;
    height: 70px;
    background-color: white;
    border-bottom: 1px solid #cccccc;
    display: flex;
    width: 100%;
    .super-tag {
      font-size: 19px;
      font-variant: small-caps;
    }
  
    .sub-tag {
      font-size: 12px;
    }
  
    .mb-container {
      vertical-align: middle;
      margin-left: auto;
      margin-right: 0;
  
      ul {
        display: flex;
        list-style: none;
        margin: 0px;
  
        li {
          padding-right: 20px;
          cursor: pointer;
  
          &:hover {
            color: #6688aa;
          }
        }
      }
    }
  }
  img {
    width: 70px;
  }
}