.resource-list {
  position: relative;
  width: 100%;
  .sermon-entry {
    margin-top: 10px;
  }
 .player-container {
    height: 400px;
    width: 400px;
    background-color: white;
    position: absolute;
    right: 0;
    top: 0;
    border: 1px solid black;
  }
}
.player {
  .label {
    margin-top: 5px;
    font-weight: bold;
  }
  .title {
    font-size: 1.2em;
    font-weight: bold;
  }
  .media-player {
    position: absolute;
    bottom: 0px;
    background-color: #dda;
    width: 100%;
    .controls {
      button {
        border: none;
        background-color: rgba(255, 255, 255, 0);
      }
    }
    .loading {
      padding-left: 200px;
      padding-top: 22px;
      height: calc(57px - 22px);
      background-color: #dda;
      width: calc(100% - 200px);
      position: absolute;
      bottom: 0px;
      .loading-fingers {
        position: absolute;
        left: 0;
        padding-left: 50px;
        background-image: url("/images/loading.gif");
        background-size: 100px 100px;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: -20px;
        width: 57px;
        height: 57px;
        margin-top: -22px;
        margin-left: calc(155px - 25px);
      }
    }
  }
}
