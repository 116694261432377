.app {
  background-color: white;
  font-family: 'Montaga';
  font-size: 14px;
  .logo, .tag {
    display: inline-block;
    margin-right: 10px;
  }
  .logo {
    font-size: 40px;
    margin-top: -10px;
    font-family: Brush Script MT;
    vertical-align: top;
  }
}
.content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 800px;
  padding: 10px 20px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 699px) {
  .web {
    display: none;
  }
}

@media (min-width: 700px) {
  .mobile {
    display: none;
  }
}