@media (max-width: 699px) {
  .translation {
    .translated {
      height:calc(50vh - 22px);
      overflow: scroll;
    }
    .notes {
      overflow: scroll;
      height: calc(50vh - 22px);
      border-top: 1px solid #cccccc;
      margin-top: 3px;
    }
  }
}
@media (min-width: 700px) {
  .translation {
    display: flex;
    .translated {
      width: 50%
    }

    .notes {
      width: 48%;
    }
  }
}

.translation {
  .translated {
    .reference {
      font-weight: bold;
    }
    .prewrap,
    .verse-line {
      padding-top: 6px;
    }
    .verse-indent-line,
    .verse-line {
      font-family: Cormorant Upright;
      font-size: 18px;
      a {
        text-decoration: none;
        border-bottom: 1px dashed #ccbbff;
        color: #664488;
      }
    }
    .verse-indent-line {
      margin-left: 20px;
      padding-top: 0px;
    }
    .verse-no {
      vertical-align: super;
      font-size: 11px;
    }
  }
  .notes {
    font-size: 14px;
    .note-line {
      display: table-row;
      .head {
        display: table-cell;
        text-align: right;
        padding-right: 8px;
      }
      .text {
        display: table-cell;
        padding-top: 3px;
        & .bold {
          color: #6644AA;
          font-weight: 600;
        }
        &.indent {
          padding-top: 0px;
          padding-left: 15px;
          color: #666666;
        }
      }
    }
    .link {
      color: #664499;
      cursor: pointer;
      &:hover {
        color: #8866cc;
      }
    }
  }
  .prewrap {
    white-space: pre-wrap;
  }
}
